<template>
  <div class="user page">
    <Nav></Nav>
    <div class="wrap" style="padding-top:10px;min-height: 700px;">
      <a-layout>
        <a-layout-sider>
          <Menu></Menu>
        </a-layout-sider>
        <a-layout>
          <a-layout-header>
            <a-row type="flex" justify="center" align="middle" class="top">
              <a-col :span="6">
                <icon-font type="icon-coupons" /> 礼券：{{ user.couponCount }}张
              </a-col>
            </a-row>
          </a-layout-header>
          <a-layout-content>
            <div :style="{ minHeight: '440px' }">
              <a-tabs default-active-key="1">
                <a-tab-pane key="1" tab="未使用">
                  <div class="cop u-flex">
                    <div class="copItem" v-for="(item, index) in coupon" :key="index">
                      <div class="l">
                        <div class="money">{{ item.amount }}</div>
                        <div class="title">
                          {{ item.name }} -- {{ item.typeName }}
                        </div>
                        <div class="time">有效时间：{{ item.endDate }}</div>
                      </div>
                      <div class="r">
                        <a-button type="danger" :disabled="false" @click="handle(item.id)">
                          立即使用
                        </a-button>
                      </div>
                    </div>
                  </div>
                  <div v-if="coupon.length == 0" syle="padding-top: 20px">
                    <a-empty description="暂无数据" />
                  </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="已使用" force-render>
                  <div class="cop u-flex">
                    <div class="copItem" v-for="(item, index) in used" :key="index">
                      <div class="l">
                        <div class="money">{{ item.amount }}</div>
                        <div class="title">
                          {{ item.name }} -- {{ item.typeName }}
                        </div>
                        <div class="time">有效时间：{{ item.endDate }}</div>
                      </div>
                      <div class="r">
                        <a-button type="danger" :disabled="true" @click="handle(item.id)">
                          立即使用
                        </a-button>
                      </div>
                    </div>
                  </div>
                  <div v-if="used.length == 0" syle="padding-top: 20px">
                    <a-empty description="暂无数据" />
                  </div>
                </a-tab-pane>
                <a-tab-pane key="3" tab="已过期">
                  <div class="cop u-flex">
                    <div class="copItem" v-for="(item, index) in invalid" :key="index">
                      <div class="l">
                        <div class="money">{{ item.amount }}</div>
                        <div class="title">
                          {{ item.name }} -- {{ item.typeName }}
                        </div>
                        <div class="time">有效时间：{{ item.endDate }}</div>
                      </div>
                      <div class="r">
                        <a-button type="danger" :disabled="true" @click="handle(item.id)">
                          立即使用
                        </a-button>
                      </div>
                    </div>
                  </div>
                  <div v-if="invalid.length == 0" syle="padding-top: 20px">
                    <a-empty description="暂无数据" />
                  </div>
                </a-tab-pane>
              </a-tabs>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
    <Foot></Foot>
</div>
</template>

<script>

import Foot from "@/components/foot";
import Nav from "@/components/Nav.vue";
import Menu from "../user/components/menu";
import {
  Icon
} from "ant-design-vue";
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2747721_gimu9jlw02u.js",
});
export default {
  components: {
    Nav,
    Foot,
    Menu,
    IconFont,
  },
  data() {
    return {
      coupon: [],
      used: [],
      invalid: [],
      user: {},
    };
  },
  computed: {},
  created() {
    if (this.$route.name === "Home") this.main_swiper = true;
    this.api
      .getMyCoupons({})
      .then((res) => {
        this.coupon = res.list;
        this.used = res.listUsed;
        this.invalid = res.listLost;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  mounted() {
    this.user = this.storage.getItem("user");
    if (!this.user) {
      this.getUserInfo();
    }
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "fetchDate",
  },
  methods: {
    fetchDate() {
      if (this.$route.name !== "Home") {
        this.main_swiper = false;
      } else {
        this.main_swiper = true;
      }
    },
    getUserInfo() {
      this.api
        .getuserDetail({})
        .then((res) => {
          this.storage.setItem("user", res);
          this.user = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handle(id) {
      this.$router.push('/post?cid=' + id);
    },
  },
};
</script>


<style lang="less">
@import "~ant-design-vue/dist/antd.less";

.page {
  background: #f5f5f5;
}

.wrap {
  height: 100%;

  // padding-bottom: 20px;
}

.ant-layout {
  height: 100%;
}

.ant-layout-sider,
.ant-layout-content,
.ant-layout-header,
.ant-layout-footer {
  background: #ffffff !important;
  padding: 10px;
}

.ant-layout-sider {
  margin-right: 10px;
  text-align: center;
}

.ant-layout-content {
  margin-top: 10px;
}

.ant-layout-header {
  height: auto;

  .top {
    .ant-col {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .anticon {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}

.user {
  .user-name {
    font-size: 14px;
    margin-top: 10px;
  }

  .other-info {
    text-align: left;
    margin-left: 30px;

    span {
      color: @text-color-secondary;
    }
  }
}

.menu {
  text-align: left;
  padding-left: 30px;
  line-height: 28px;
  margin-top: 30px;
  border-top: 1px solid #e2e2e2;

  dl {
    padding-top: 10px;

    dt {
      font-size: 16px;
      line-height: 28px;
    }

    dd {
      color: @text-color-secondary;
    }
  }
}

div.content-wrap {
  padding: 15px;
}

.cop {
  flex-wrap: wrap;
  justify-content: space-between;

  .copItem {
    width: 480px;
    display: flex;
    justify-content: space-between;
    background: #FEF9DF;
    align-items: center;
    position: relative;
    border-radius: 8px;
    margin-bottom: 10px;

    .l {
      flex: 1;
      border-right: 2px dotted #999;
      width: 60%;
      padding: 9px 0;
      padding-left: 9px;

      .money {
        font-size: 36px;
        color: #CF0022;
      }

      .title {
        padding: 5px 0;
      }

      .time {
        color: #909399;
      }
    }

    .r {
      text-align: center;
      width: 30%;

      .ant-btn-danger {
        background: #CF0022;
        border: #CF0022 1px solid;
      }

      .ant-btn-danger:hover {
        opacity: .85;
      }

      .ant-btn-danger[disabled] {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        text-shadow: none;
        box-shadow: none;
      }
    }
  }
}
</style>