<template>
  <div class="user page">
    <Nav></Nav>
    <div class="wrap" style="padding-top:10px;min-height: 700px;">
      <a-layout>
        <a-layout-sider>
          <Menu></Menu>
        </a-layout-sider>
        <a-layout>
          <a-layout-header>
            <a-row type="flex" justify="center" align="middle" class="top">
              <a-col :span="6">
                基础信息
              </a-col>
            </a-row>
          </a-layout-header>
          <a-layout-content>
            <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-form-model-item label="头像">
                <div style="text-align: right;width: 100%;">
                  <a-upload name="file" list-type="picture-card" :show-upload-list="false" :customRequest="handleUpload"
                    :before-upload="beforeUpload" @change="handleChange">
                    <a-avatar :size="64" :src="headimg" icon="user" />
                  </a-upload>
                </div>
              </a-form-model-item>
              <a-form-model-item ref="nickName" label="昵称" prop="nickName">
                <a-input v-model="form.nickName" @blur="
                  () => {
                    $refs.nickName.onFieldBlur();
                  }
                " placeholder="请输入昵称"></a-input>
              </a-form-model-item>
              <a-form-model-item ref="name" label="姓名" prop="name">
                <a-input v-model="form.name" @blur="
                  () => {
                    $refs.name.onFieldBlur();
                  }
                " placeholder="请输入姓名"></a-input>
              </a-form-model-item>
              <a-form-model-item label="性别">
                <a-radio-group v-model="form.sex">
                  <a-radio :value="0">保密</a-radio>
                  <a-radio :value="1">男</a-radio>
                  <a-radio :value="2">女</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                <a-button type="primary" html-type="submit" @click="onSubmit">
                  保存
                </a-button>
              </a-form-item>
            </a-form-model>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Foot from "@/components/foot";
import Nav from "@/components/Nav.vue";
import Menu from "../user/components/menu";
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
  components: {
    Nav,
    Foot,
    Menu,
  },
  data() {
    return {
      user: {},
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      loading: false,
      headimg: '',
      action: '',
      headers: {},
      form: {
        errorType: ["message"],
        labelPosition: "top",
        nickName: "",
        name: "",
        sex: '',
      },
      rules: {
        nickName: [{
          required: true,
          message: "请输入昵称",
          trigger: "blur"
        },
        {
          min: 2,
          max: 50,
          message: "请输入正确的昵称",
          trigger: "blur"
        },
        ],
        name: [{
          required: true,
          message: "请输入姓名",
          trigger: "blur"
        },
        {
          min: 2,
          max: 50,
          message: "请输入正确的姓名",
          trigger: "blur"
        },
        ],
      },
    };
  },
  computed: {},
  created() {
    if (this.$route.name === "Home") this.main_swiper = true;
  },
  mounted() {
    this.getUserInfo();
    this.action = this.api.apiUrl + '/user/uploaduserhead';
    this.headers = { 'access_token': this.storage.getItem("token") };
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "fetchDate",
  },
  methods: {
    fetchDate() {
      if (this.$route.name !== "Home") {
        this.main_swiper = false;
      } else {
        this.main_swiper = true;
      }
    },
    getUserInfo() {
      this.api
        .getuserDetail({})
        .then((res) => {
          this.storage.setItem("user", res);
          this.user = res;
          this.form.nickName = res.nickName;
          this.form.name = res.name;
          this.form.sex = res.sex;
          this.headimg = res.head;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.api.seaveusersimp({
            name: this.form.name,
            nickName: this.form.nickName,
            sex: this.form.sex,
          }).then(res => {
            var user = this.storage.getItem("user");
            user.name = this.form.name;
            user.nickName = this.form.nickName;
            user.sex = this.form.sex;
            this.storage.setItem("user", res);
            this.$message.success('设置成功');
          }).catch(err => {
            console.log(err);
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (info.file.status === 'done') {
        getBase64(info.file.originFileObj, imageUrl => {
          this.headimg = imageUrl;
          this.loading = false;
        });
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('请选择图片类型文件');
      }
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.$message.error('图片大小应小于 20MB!');
      }
      return isJpgOrPng && isLt2M;
    },
    handleUpload(file) {
      const formData = new FormData();
      formData.append('files[]', file.file);
      this.api.uploaduserhead(formData).then(res => {
        var user = this.storage.getItem("user");
        user.head = res.fileUrl;
        this.headimg = user.head;
        this.storage.setItem("user", res);
        this.$message.success('设置成功');
      }).catch(err => {
        console.log(err);
      });
    },
  },
};
</script>


<style lang="less">
@import "~ant-design-vue/dist/antd.less";

.page {
  background: #f5f5f5;
}

.wrap {
  height: 100%;

  // padding-bottom: 20px;
}

.ant-layout {
  height: 100%;
}

.ant-layout-sider,
.ant-layout-content,
.ant-layout-header,
.ant-layout-footer {
  background: #ffffff !important;
  padding: 10px;
}

.ant-layout-sider {
  margin-right: 10px;
  text-align: center;
}

.ant-layout-content {
  margin-top: 10px;
}

.ant-layout-header {
  height: auto;

  .top {
    .ant-col {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .anticon {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}

.user {
  .user-name {
    font-size: 14px;
    margin-top: 10px;
  }

  .other-info {
    text-align: left;
    margin-left: 30px;

    span {
      color: @text-color-secondary;
    }
  }
}

.menu {
  text-align: left;
  padding-left: 30px;
  line-height: 28px;
  margin-top: 30px;
  border-top: 1px solid #e2e2e2;

  dl {
    padding-top: 10px;

    dt {
      font-size: 16px;
      line-height: 28px;
    }

    dd {
      color: @text-color-secondary;
    }
  }
}

div.content-wrap {
  padding: 15px;
}

.num {
  color: red;
  padding-right: 10px;
}

.bla-border {
  border: 0 solid #fff;
  border-radius: 0;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  background: #fff;
  padding-bottom: 20px;
  padding-left: 100px;
  padding-right: 100px;
}

.bla-top {
  background-size: cover;
  padding: 15px;
}

.bla-head {
  display: flex;
  justify-content: space-between;

  .bla-txt {
    font-size: 36px;
    color: #CF0022;
    margin-top: 5px;
  }
}

.bla-foot {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .bla-invest-total {
    .bla-txt {
      font-size: 36px;
      color: #CF0022;
      margin-top: 5px;
    }
  }

  .bla-consume-total {
    .bla-txt {
      font-size: 36px;
      color: #CF0022;
      margin-top: 5px;
    }
  }
}

.sign-coupon {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;

  .sign {
    background: #fff6d1;
  }

  .coupon {
    background: #fff3f3;
  }

  .item {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    width: 48%;
    padding: 9px;
    color: #CF0022;

    .u-icon--right {
      flex-direction: row;
      align-items: center;
      font-size: 30px;
    }

    .info {
      font-weight: 400;
      font-size: 11px;
      margin-top: 5px;
    }

    .txt {
      font-size: 15px;
      font-weight: 600;
    }
  }
}

.ant-upload-picture-card-wrapper {
  width: auto;
}
</style>